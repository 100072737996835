let AccountsAdrs = "";
let EventEmitterAdrs = "";
let GameAdrs = "";
let ETHAdrs = "";

if(process.env.REACT_APP_ENV == "DEV") {
  AccountsAdrs = "0x0172ccb303400b8376329361c7154bb9d3bac546364b47ba5a54fd19bd3a1395";
  EventEmitterAdrs = "0x01b8b97a54fc5871bb9a4b62887cb5a732fafbc3b4a460c828a46008aef22cc4";
  GameAdrs = "0x0481def6441c8431e19951aa9d74f18e7abf5e5aad83e6ca122fa103efa80acc";
  ETHAdrs = "0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7";
}
else if(process.env.REACT_APP_ENV == "TEST") {
  AccountsAdrs = "0x030a345c7e6db591c91604bcae4033c2543dce3aa51fb4fe295c012da4effc8a";
  GameAdrs = "0x02138e2348c6e477be10b5b7f3a852b949efb66112f039afabaf2de641b79bd9";
  ETHAdrs = "0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7";
}
else if(process.env.REACT_APP_ENV == "PROD") {
  // AccountsAdrs = "0x07f8b470057f47f3c2c4f60db801f4df4a23d4b6969b200876685607e50f6a53";
  AccountsAdrs = "0x07ba68bc3505dc7db6555e5bcce98cf408fc9afe75f47007effd9bc7b8e557e4";
  // GameAdrs = "0x02e798fa9fb684fe729823fa68e594d6885a159ac4261a09f761bc0ff96ed43d";
  GameAdrs = "0x05a38de2da899a95770939d4ffb3fd4223b55b4d02765c3575438a826f6a78fd";
  ETHAdrs = "0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7";
}


export { AccountsAdrs, EventEmitterAdrs, GameAdrs, ETHAdrs }